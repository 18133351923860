/**
 * Module containing constants for breakpoints and other resposive design considerations
 */
import { css } from "lit";

export const SCREEN_SIZE_SMALL = 501; // px

/**
 * Reusable CSS stylesheet for responsive display
 * Implements a subset of Bootstrap's CSS, but with only two sizes (sm [default] and lg)
 * https://getbootstrap.com/docs/4.0/utilities/display/
 */
export const cssResponsiveUtils = css`
  .d-none {
    display: none;
  }
  .d-block {
    display: block;
  }
  .d-inline {
    display: inline;
  }
  @media screen and (min-width: ${SCREEN_SIZE_SMALL}px) {
    .d-lg-none {
      display: none;
    }
    .d-lg-block {
      display: block;
    }
    .d-lg-inline {
      display: inline;
    }
  }
`;
